/* eslint-disable no-param-reassign */
import {
  IArrayType,
  IMaybe,
  IMSTArray,
  ISimpleType,
  IStateTreeNode,
  types,
} from 'mobx-state-tree'
import { roleTypes, timestampTypes } from '../types/common'

export const PostModel = types
  .model('Post', {
    _id: types.identifier,
    author: types.string,
    ...timestampTypes,
    likes: types.array(types.string),
    documentsIds: types.array(types.string),
    facilitiesIds: types.array(types.string),
    commentIds: types.maybe(types.array(types.string)),
    pollIds: types.array(types.string),
    societyContactsIds: types.array(types.string),
    boardroomContactsIds: types.array(types.string),
    // Sometimes posts include references to media that does not exist..
    // Using string instead of reference (safeReference is preferred) and filtering on undefined
    media: types.array(types.string),
    // SafeReference would not resolve parent correctly, so I decided
    // to use a string with id array now as to filter out undefined
    // when a event is removed in calendar but exists on post object.
    resourcesIds: types.array(types.string),
    chatRoomIds: types.array(types.string),
    eventsIds: types.array(types.string),
    boardroomEventsIds: types.array(types.string),
    boardroomEventDeleteData: types.maybe(
      types.model({
        _id: types.identifier,
        title: types.string,
        description: types.maybe(types.string),
        location: types.maybe(types.string),
        isAllDay: types.maybe(types.boolean),
        startDate: types.string,
        endDate: types.string,
        societyId: types.string,
        userId: types.string,
        notificationSettings: types.string,
      })
    ),
    eventDeleteData: types.maybe(
      types.model({
        _id: types.identifier,
        title: types.string,
        description: types.maybe(types.string),
        location: types.maybe(types.string),
        isAllDay: types.maybe(types.boolean),
        startDate: types.string,
        endDate: types.string,
        societyId: types.string,
        notificationSettings: types.string,
        userId: types.string,
      })
    ),
    groupsIds: types.array(types.string),
    localOfferIds: types.array(types.string),
    isAlert: types.maybe(types.boolean),
    message: types.string,
    society: types.string,
    role: roleTypes,
    accessBoardMember: types.maybe(types.boolean),
    accessAdminMember: types.maybe(types.boolean),
    accessUsersIds: types.maybe(types.array(types.string)),
    seenBy: types.maybe(
      types.array(
        types.model({
          userId: types.string,
          seenAt: types.string,
        })
      )
    ),
  })
  .actions((self) => ({
    toggleLike(userId: string, like: boolean) {
      if (like && !self.likes.includes(userId)) {
        self.likes.push(userId)
      } else {
        self.likes = self.likes.filter(
          (_like) => _like !== userId
        ) as IMSTArray<ISimpleType<string>> &
          IStateTreeNode<IArrayType<ISimpleType<string>>>
      }
    },
    addComment(id: string) {
      self.commentIds?.push(id)
    },
    removeComment(id: string) {
      self.commentIds = self.commentIds?.filter((_id) => _id !== id) as
        | (IMSTArray<ISimpleType<string>> &
            IStateTreeNode<IMaybe<IArrayType<ISimpleType<string>>>>)
        | undefined
    },
  }))
