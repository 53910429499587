import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Button, ButtonVariant } from '../../common/Button'
import { IconChoices } from '../../common/Icon'
import { ProgressBar } from '../../common/ProgressBar'
import { theme } from '../../../theme/theme'

interface OnboardinSectionProps {
  title: string
  subtitle: string
  step: number
  totalSteps: number
  goForward: () => void
  goBack: () => void
  finishGuide: () => void
}

export const OnboardingSection = ({
  title,
  subtitle,
  step,
  totalSteps,
  goForward,
  goBack,
  finishGuide,
}: OnboardinSectionProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const getProgressPercentage = (): number => {
    return (step / totalSteps) * 100
  }

  const progressPercentage = getProgressPercentage()

  const onForwardClick = (): void => {
    if (step === totalSteps) {
      finishGuide()
    }
    goForward()
  }

  return (
    <div className="flex w-full flex-col space-y-12 md:space-y-24">
      <div className="flex w-full flex-col space-y-2">
        <div className="flex items-center justify-between">
          <Button
            disabled={step === 1}
            size="xs"
            variant={ButtonVariant.SECONDARY}
            icon={IconChoices.ARROW_LEFT}
            onClick={goBack}
          />
          <p style={theme.textVariants.caption}>
            {translate('onboardingSection.step', { step })}
            {`/${totalSteps} - `}
            {title}
          </p>
          <Button
            disabled={step === totalSteps}
            size="xs"
            variant={ButtonVariant.SECONDARY}
            icon={IconChoices.ARROW}
            onClick={goForward}
          />
        </div>
        <ProgressBar
          percentage={progressPercentage}
          number={step}
          height="h-2"
          animate
        />
      </div>
      <div className="ml-0 flex flex-col md:ml-48">
        <p style={theme.textVariants.baseBold} className="mt-3">
          {translate('onboardingSection.step', { step })}
        </p>
        <p style={theme.textVariants.h2} className="mt-1">
          {title}
        </p>
        <p
          className="mt-2 max-w-[600px] text-neutral-40"
          style={theme.textVariants.base}
        >
          {subtitle}
        </p>
      </div>
      <div
        className={`flex flex-col ${
          step !== 1 ? 'justify-between' : 'justify-end'
        } space-y-2 md:flex-row md:space-y-0`}
      >
        {step !== 1 && (
          <Button
            wrapperClassName="md:ml-48 ml-0"
            className="w-full"
            onClick={goBack}
            label={translate('onboardingSection.previousStep')}
            icon={IconChoices.ARROW_LEFT}
          />
        )}
        <Button
          wrapperClassName="md:mr-20 mr-0"
          className="float-left w-full md:float-right"
          onClick={onForwardClick}
          label={translate(
            step === totalSteps
              ? 'onboardingSection.lastStep'
              : 'onboardingSection.nextStep'
          )}
          variant={ButtonVariant.PRIMARY}
          icon={step === totalSteps ? IconChoices.CHECKMARK : IconChoices.ARROW}
          iconPlacement="right"
        />
      </div>
    </div>
  )
}
