import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'

import { observer } from 'mobx-react-lite'
import { PostBoardroomEventDelete } from '../PostBoardroomEventDelete'
import { BoardRoomEventDeleteModel } from '../../../state/models/boardroom-event-delete'

interface PostBoardroomEventsDeleteProps {
  event?: SnapshotOut<typeof BoardRoomEventDeleteModel>
}

export const PostBoardroomEventsDelete = observer(
  ({ event }: PostBoardroomEventsDeleteProps): JSX.Element | null => {
    if (!event) {
      return null
    }

    return (
      <>
        <div key={event._id} className="flex flex-col">
          <div className="my-0 border-b border-neutral-80 first:hidden" />
          <PostBoardroomEventDelete key={event._id} event={event} />
        </div>
      </>
    )
  }
)
