import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { SocietyContactModel } from '../../../../state/models/society-contact'
import { societyContactCreateSchema } from '../../../../forms/schemas/society_contact_create'
import { useStores } from '../../../../hooks/useStores'
import { NBoardroomContacts } from '../../../../interfaces/services/boardroom-contacts.interfaces'
import { CreateUpdateContactForm } from '../CreateUpdateContactForm'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'

interface CreateUpdateContactModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  contact?: SnapshotOut<typeof SocietyContactModel>
  management?: boolean
}

export const CreateUpdateContactModal = observer(
  ({
    contact,
    children,
    show,
    management,
    close,
  }: CreateUpdateContactModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyBoardroomContactsStore, societyContactsStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!contact
    const store = management
      ? societyBoardroomContactsStore
      : societyContactsStore

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateContactModal.flashMessage.updateContactFailure'
        : 'createUpdateContactModal.flashMessage.createContactFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateContactModal.flashMessage.updateContactSuccess'
        : 'createUpdateContactModal.flashMessage.createContactSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyContactCreateSchema>
    ): Promise<void> => {
      const dataToSend = {
        ...data,
        website: data.website || '',
        email: data.email || '',
      }

      if (updateMode) {
        const status = await store.patchContact(
          contact._id,
          dataToSend as NBoardroomContacts.NPatch.IRequestBody
        )

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await store.createContact(
          dataToSend as NBoardroomContacts.NCreate.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      store.creatingContact === 'pending' || store.updatingContact === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateContactModal.editContactTitle'
                    : 'createUpdateContactModal.addNewContactTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateContactForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  contact={contact}
                  isBoardMember={management}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
