import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const FacilityWeeklySchedule = types.model('FacilityWeeklySchedule', {
  allowed: types.boolean,
  startTime: types.string,
  endTime: types.string,
})

export const FacilityModel = types
  .model('Facility', {
    _id: types.identifier,
    ...timestampTypes,
    name: types.string,
    societyId: types.string,
    booking: types.boolean,
    rules: types.enumeration('FacilityBookingRule', [
      'one-active',
      'two-active',
      'three-active',
      'four-active',
      'five-active',
      'unlimited',
    ]),
    visibility: types.enumeration('FacilityBookingVisibility', [
      'all',
      'adminonly',
      'board',
    ]),
    coverPhotoId: types.maybe(types.string),
    description: types.maybe(types.string),
    bookingGranularity: types.enumeration('FacilityBookingGranularity', [
      '1hr',
      '2hr',
      '3hr',
      '4hr',
      '5hr',
      'full-day',
    ]),
    bookingMessage: types.maybe(types.string),
    weeklySchedule: types.maybe(types.array(FacilityWeeklySchedule)),
    bookingAvailability: types.maybe(
      types.array(
        types.model('FacilityBookingAvailability', {
          startDate: types.maybeNull(types.string),
          endDate: types.maybeNull(types.string),
        })
      )
    ),
    blockedDates: types.maybe(types.array(types.string)),
    notificationEmails: types.maybe(types.array(types.string)),
    userId: types.maybe(types.string),
    notificationSettings: types.maybe(types.string),
  })
  .views((self) => ({
    getRuleAsInteger() {
      switch (self.rules) {
        case 'one-active':
          return 1
        case 'two-active':
          return 2
        case 'three-active':
          return 3
        case 'four-active':
          return 4
        case 'five-active':
          return 5
        default:
          return 1
      }
    },
    getBookingGranularityHours() {
      switch (self.bookingGranularity) {
        case '1hr':
          return 1
        case '2hr':
          return 2
        case '3hr':
          return 3
        case '4hr':
          return 4
        case '5hr':
          return 5
        default:
          return 1
      }
    },
  }))
