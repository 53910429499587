import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../../theme/theme'
import { Button, ButtonVariant } from '../../../common/Button'
import { Icon, IconChoices } from '../../../common/Icon'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalFooter } from '../../../common/Modal/ModalFooter'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import {
  NOTIFICATION_SETTINGS_VALUE,
  notificationSettingsOptions,
} from '../../../../helpers/notification_settings_options'
import { EventModel } from '../../../../state/models/event'
import { societyEventRemoveSchema } from '../../../../forms/schemas/society_event_remove'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { BoardRoomEventModel } from '../../../../state/models/boardroom-event'

interface RemoveConfirmationModalProps {
  close: () => void
  show: boolean
  title: string
  description?: string
  event: SnapshotOut<typeof EventModel | typeof BoardRoomEventModel>
  deleteMode?: boolean
  confirmationButtonLabel?: string
  boardRoomEvent?: boolean
}

export const RemoveEventModal = ({
  show,
  title,
  description,
  close,
  event,
  deleteMode = true,
  confirmationButtonLabel,
  boardRoomEvent,
}: RemoveConfirmationModalProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { eventStore, boardRoomEventStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const navigate = useNavigate()

  const cancel = (): void => {
    close()
  }
  const onSubmit = async (
    onValid: InferType<typeof societyEventRemoveSchema>
  ): Promise<void> => {
    const status = boardRoomEvent
      ? await boardRoomEventStore.deleteEvent(
          event._id,
          onValid.notificationSettings
        )
      : await eventStore.deleteEvent(event._id, onValid.notificationSettings)

    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('createUpdateEvent.flashMessage.deleteEventSuccess')
      )
      if (boardRoomEvent)
        navigate(reverseUrl('management:calendar', { id: event.societyId }))
      else navigate(reverseUrl('society:calendar', { id: event.societyId }))
      close()
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('createUpdateEvent.flashMessage.deleteEventFailure')
      )
    }
  }

  const { getErrorMessage } = useFormErrorMessage()

  const getDefaultValues = useCallback((): InferType<
    typeof societyEventRemoveSchema
  > => {
    return {
      notificationSettings:
        event?.notificationSettings as NOTIFICATION_SETTINGS_VALUE,
    }
  }, [event])

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(societyEventRemoveSchema),
    defaultValues: getDefaultValues(),
  })

  const getConfirmationButtonLabel = (): string => {
    if (confirmationButtonLabel) {
      return confirmationButtonLabel
    }
    return translate(deleteMode ? 'common.actions.delete' : 'common.yes')
  }

  return (
    <Modal show={show} size="sm">
      {{
        header: <ModalHeader style={theme.textVariants.lead} onClose={close} />,
        body: (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <div className="flex items-start gap-3 items-center pb-8">
                <Icon icon={IconChoices.EXCLAMATION_ALERT} />
                <p style={theme.textVariants.h3}>{title}</p>
              </div>
              <p className="text-neutral-40" style={theme.textVariants.base}>
                {description}
              </p>
              <Controller
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    label={translate('common.notificationSettings.label')}
                    name={name}
                    error={
                      errors.notificationSettings &&
                      getErrorMessage(errors.notificationSettings)
                    }
                  >
                    <SelectDropdown
                      value={value}
                      onChange={onChange}
                      options={notificationSettingsOptions}
                      error={!!errors.notificationSettings}
                    />
                  </FormControl>
                )}
                name="notificationSettings"
              />
            </ModalBody>
            <ModalFooter className="flex flex-row gap-3">
              <Button
                type="button"
                label={`${translate('common.actions.cancel')}`}
                onClick={cancel}
                size="sm"
              />
              <Button
                type="submit"
                label={getConfirmationButtonLabel()}
                size={deleteMode ? 'sm' : 'sm-wide'}
                variant={ButtonVariant.DANGER}
              />
            </ModalFooter>
          </form>
        ),
      }}
    </Modal>
  )
}
