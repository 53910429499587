import React, { useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { TextArea } from '../../../common/TextArea'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { ResourceModel } from '../../../../state/models/resource'
import { societyResourceCreateSchema } from '../../../../forms/schemas/society_resource_create'
import { useStores } from '../../../../hooks/useStores'
import { DropZone, DropZoneVariant } from '../../../common/DropZone'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { MediaImage } from '../../../common/Image'
import { notificationSettingsOptions } from '../../../../helpers/notification_settings_options'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { FormSpacing } from '../../../common/FormSpacing'
import { IResourceModel } from '../../../../interfaces/models/resources.interfaces'

interface CreateUpdateMarketplaceItemFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyResourceCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  resource?: SnapshotOut<typeof ResourceModel>
}

export const CreateUpdateMarketplaceItemForm = observer(
  ({
    resource,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateMarketplaceItemFormProps): JSX.Element => {
    const { authenticationStore, mediaStore, unitStore } = useStores()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    const userId = authenticationStore.userId as string

    const userSocietyUnits = useMemo(() => {
      return authenticationStore.userId
        ? unitStore.userUnits(
            authenticationStore.userId as string,
            society._id,
            true
          )
        : []
    }, [authenticationStore.userId, society, unitStore])

    const updateMode = !!resource

    const getDefaultValues = useCallback((): InferType<
      typeof societyResourceCreateSchema
    > => {
      return {
        societyId: updateMode
          ? resource?.societyId || society._id
          : society._id,
        unit: updateMode
          ? resource?.unit || userSocietyUnits[0]._id
          : userSocietyUnits[0]._id,
        userId: updateMode ? resource?.userId || userId : userId,
        title: updateMode ? resource?.title || '' : '',
        description: updateMode ? resource?.description || '' : '',
        cost: updateMode ? resource?.cost || '0' : '0',
        mediaId: updateMode ? resource?.mediaId || '' : '',
        notificationSettings: 'none',
        type: updateMode
          ? (resource?.type as IResourceModel['type'] | undefined) || 'sell'
          : 'sell',
        isFree: updateMode ? resource?.isFree || false : false, // Deprecated. Cost will be 0 instead.
      }
    }, [society, resource, updateMode, userId, userSocietyUnits])

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyResourceCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const uploadImage = async (base64: string): Promise<void> => {
      setUploadingPicture(true)

      try {
        const mediaId = await mediaStore.createMedia('image', base64)
        if (!mediaId) {
          throw new Error('no mediaId returned by createMedia')
        }

        setValue('mediaId', mediaId, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    return (
      <FormSpacing>
        <Controller
          control={control}
          render={({ field: { name, value } }) => (
            <FormControl
              label={translate('createUpdateResourceModal.labels.media')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              {value ? (
                <div className="flex justify-center md:h-80">
                  <MediaImage
                    mediaId={value}
                    size={96}
                    onDeleteClick={() =>
                      setValue('mediaId', '', {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                    objectFit="object-contain"
                  />
                </div>
              ) : (
                <div>
                  <DropZone
                    loading={uploadingPicture}
                    onUpload={(status, file) => {
                      if (status === 'accepted') {
                        uploadImage(file.base64)
                      } else {
                        setToastNotification(
                          ToastType.DANGER,
                          translate('flashMessage.somethingWentWrongError')
                        )
                      }
                    }}
                    variant={DropZoneVariant.MEDIA}
                  />
                </div>
              )}
            </FormControl>
          )}
          name="mediaId"
        />
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateResourceModal.labels.title')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateResourceModal.labels.description')}
              name={name}
              error={errors.description && getErrorMessage(errors.description)}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={translate(
                  'createUpdateResourceModal.placeholders.description'
                )}
                error={!!errors.description}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="cost"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateResourceModal.labels.cost')}
              error={errors.cost && getErrorMessage(errors.cost)}
              name={name}
            >
              {/*
                  TODO: (future PR)
                  TextInput should have hint.
              */}
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.cost}
                type="number"
                disableOnWheel
              />
            </FormControl>
          )}
        />

        {!society.settings?.marketplacePostingDisabled && (
          <Controller
            control={control}
            name="notificationSettings"
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={translate('common.notificationSettings.label')}
                name={name}
                error={
                  errors.notificationSettings &&
                  getErrorMessage(errors.notificationSettings)
                }
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                  error={!!errors.notificationSettings}
                />
              </FormControl>
            )}
          />
        )}
        <div className="mt-6 flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            label={
              updateMode
                ? translate('common.actions.save')
                : translate('common.actions.create')
            }
            variant={ButtonVariant.PRIMARY}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
