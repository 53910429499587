import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../../common/Button'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { societyDocumentUploadSchema } from '../../../../../forms/schemas/society_document_upload'
import { DropZone } from '../../../../common/DropZone'
import { FormControl } from '../../../../common/FormControl'
import { TextInput } from '../../../../common/TextInput'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../common/Toast/toast-type'
import { SelectDropdown } from '../../../../common/SelectDropdown'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../../helpers/notification_settings_options'
import { theme } from '../../../../../theme/theme'

interface CreateDocumentFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyDocumentUploadSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  isBoardDocuments: boolean
}

export const CreateDocumentForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
    isBoardDocuments,
  }: CreateDocumentFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = useCallback((): InferType<
      typeof societyDocumentUploadSchema
    > => {
      return {
        files: [],
        notificationSettings: NOTIFICATION_SETTINGS_VALUE.NONE,
      }
    }, [])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyDocumentUploadSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'files',
      control,
    })

    return (
      <>
        <Controller
          control={control}
          render={() => (
            <DropZone
              onUpload={(status, file) => {
                if (status === 'accepted') {
                  append({
                    mimeType: file.type,
                    base64: file.base64,
                    filename: file.name,
                    name: file.name,
                  })
                } else {
                  setToastNotification(
                    ToastType.DANGER,
                    translate('flashMessage.somethingWentWrongError')
                  )
                }
              }}
              multiple
            />
          )}
          name="files"
        />
        <div className="flex flex-col gap-2">
          {fields.length > 0 && (
            <p style={theme.textVariants.base} className="mt-4">
              {translate('createUpdateDocumentForm.uploadedFiles')}
            </p>
          )}
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="flex w-full flex-row items-center space-x-4"
            >
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    className="w-full"
                    error={
                      errors.files?.[index]?.name?.type === 'required'
                        ? translate('form.errors.required')
                        : ''
                    }
                    name={`files.${index}.name`}
                  >
                    <TextInput
                      className="w-full"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.files?.[index]?.name}
                      onDeleteIconPress={() => remove(index)}
                    />
                  </FormControl>
                )}
                name={`files.${index}.name`}
              />
            </div>
          ))}
        </div>
        {fields.length > 0 && (
          <Controller
            control={control}
            name="notificationSettings"
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={
                  isBoardDocuments
                    ? translate('common.notificationSettings.labelBoard')
                    : translate('common.notificationSettings.label')
                }
                labelStyle={theme.textVariants.base}
                name={name}
                error={
                  errors.notificationSettings &&
                  getErrorMessage(errors.notificationSettings)
                }
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                  error={!!errors.notificationSettings}
                />
              </FormControl>
            )}
          />
        )}
        <div className="mt-4 flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            loading={loading}
            disabled={!isValid || loading}
            label={translate('common.actions.upload')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
