/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { flow, types, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { NPosts } from '../../interfaces/services/posts.interfaces'
import { IPostModel } from '../../interfaces/models/posts.interfaces'
import { ISocietiesModel } from '../../interfaces/models/societies.interfaces'
import { IUserModel } from '../../interfaces/models/users.interfaces'
import { IMediaModel } from '../../interfaces/models/media.interfaces'
import { ICommentModel } from '../../interfaces/models/comments.interfaces'
import { IDocumentModel } from '../../interfaces/models/documents.interfaces'
import { IPollModel } from '../../interfaces/models/polls.interfaces'
import {
  getFeed as apiGetFeed,
  getPost as apiGetPost,
  toggleLike as apiToggleLike,
  remove as apiRemove,
  create as apiCreate,
  update as apiUpdate,
  report as apiReport,
} from '../../api/posts'
import { PostModel } from '../models/post'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { IEventModel } from '../../interfaces/models/events.interfaces'
import { IResourceModel } from '../../interfaces/models/resources.interfaces'
import { IChatRoomModel } from '../../interfaces/models/chat-rooms.interfaces'
import { IFacilityModel } from '../../interfaces/models/facilities.interfaces'
import { ISocietyContactModel } from '../../interfaces/models/society-contacts.interfaces'
import { IBoardroomContactModel } from '../../interfaces/models/boardroom-contacts.interfaces'
import { IGroupModel } from '../../interfaces/models/groups.interfaces'
import { ILocalOfferModel } from '../../interfaces/models/local-offers.interfaces'
import { IBoardRoomEventModel } from '../../interfaces/models/boardroom-events.interfaces'

export const PostStore = types
  .model('PostStore')
  .props({
    posts: types.map(PostModel),
    fetchingPostState: types.map(stateType),
    postFilters: types.array(types.string),
    societyFilters: types.array(types.string),
    fetchingFeed: stateType,
    creatingPost: stateType,
    updatingPost: stateType,
    reportingPost: stateType,
    hasFetchedPostsOnce: types.boolean,
  })
  .views((self) => ({
    get sortedPosts(): SnapshotOut<typeof PostModel>[] {
      // @ts-ignore
      return (values(self.posts) as SnapshotOut<typeof PostModel>[]).sort(
        (a, b) => sortByDate(a.createdAt, b.createdAt, true)
      )
    },
  }))
  .views((self) => ({
    get filteredPosts() {
      let filteredPosts = self.sortedPosts

      // If includes nothing both board and neighbour, do nothing
      if (self.postFilters.length > 0) {
        if (
          self.postFilters.includes('board') &&
          !self.postFilters.includes('neighbour')
        ) {
          filteredPosts = filteredPosts.filter(
            (post) =>
              post.role === 'board-member' || post.role === 'board-society'
          )
        }

        if (
          self.postFilters.includes('neighbour') &&
          !self.postFilters.includes('board')
        ) {
          filteredPosts = filteredPosts.filter(
            (post) =>
              post.role !== 'board-member' && post.role !== 'board-society'
          )
        }

        if (self.postFilters.includes('important')) {
          filteredPosts = filteredPosts.filter((post) => post.isAlert === true)
        }
      }

      if (self.societyFilters.length > 0) {
        filteredPosts = filteredPosts.filter((post) =>
          self.societyFilters.includes(post.society)
        )
      }

      return filteredPosts
    },
  }))
  .views((self) => ({
    get feed() {
      return self.filteredPosts
    },
  }))
  .views((self) => ({
    get lastKnownFeedDate() {
      const { feed } = self
      const date = feed[feed.length - 1]?.createdAt
      return date ? new Date(date) : undefined
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.posts.clear()
      self.postFilters.clear()
      self.societyFilters.clear()
      self.fetchingFeed = 'none'
      self.creatingPost = 'none'
      self.updatingPost = 'none'
      self.hasFetchedPostsOnce = false
    },
    fetchingPost: (id: string): string => {
      const _fetchingPost = self.fetchingPostState.get(id)
      if (_fetchingPost) {
        return _fetchingPost
      }

      self.fetchingPostState.set(id, 'done')
      return 'done'
    },
    setPosts: (posts: IPostModel[]) => {
      posts.forEach((post) => {
        // @ts-ignore
        setObject<typeof PostModel>(self.posts, PostModel, {
          ...post,
          polls: post.pollIds,
          events: post.eventsIds,
        })
      })
    },
    deletePost: (id: string) => {
      self.posts.delete(id)
    },
    deletePosts: (ids: string[]): void => {
      ids.forEach((_id) => self.posts.delete(_id))
    },
    setHasFetchedPostsOnce: (val: boolean) => {
      self.hasFetchedPostsOnce = val
    },
  }))
  .actions((self) => ({
    setPostFilters: (roles: string[]) => {
      // @ts-ignore
      self.postFilters = roles
    },
    setSocietyFilters: (societyIds: string[]) => {
      // @ts-ignore
      self.societyFilters = societyIds
    },
    processPosts: (
      posts: IPostModel[],
      comments?: ICommentModel[],
      users?: IUserModel[],
      media?: IMediaModel[],
      documents?: IDocumentModel[],
      polls?: IPollModel[],
      events?: IEventModel[],
      boardroomEvents?: IBoardRoomEventModel[],
      societies?: ISocietiesModel[],
      resources?: IResourceModel[],
      chatRooms?: IChatRoomModel[],
      facilities?: IFacilityModel[],
      societyContacts?: ISocietyContactModel[],
      boardRoomContacts?: IBoardroomContactModel[],
      groups?: IGroupModel[],
      localOffers?: ILocalOfferModel[]
    ) => {
      const processedPosts = posts.map((post) => ({
        ...post,
        commentIds: comments
          ? comments
              .filter((comment) => comment.postId === post._id)
              .map((comment) => comment._id)
          : [],
        resourcesIds: post.resourcesIds,
      }))

      const updateStore = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        func: (arg: any) => void,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        collection?: any
      ): void => {
        if (collection) {
          func(collection)
        }
      }

      const {
        commentStore,
        userStore,
        mediaStore,
        documentStore,
        pollStore,
        societyStore,
        eventStore,
        boardRoomEventStore,
        resourcesStore,
        chatRoomStore,
        facilitiesStore,
        societyContactsStore,
        societyBoardroomContactsStore,
        groupStore,
        localOffersStore,
      } = getRoot<RootStore>(self)
      updateStore(mediaStore.setMedia, media)
      updateStore(userStore.setUsers, users)
      updateStore(commentStore.setComments, comments)
      updateStore(societyStore.setSocieties, societies)
      updateStore(documentStore.setDocuments, documents)
      updateStore(pollStore.setPolls, polls)
      updateStore(eventStore.setEvents, events)
      updateStore(boardRoomEventStore.setEvents, boardroomEvents)

      updateStore(resourcesStore.setResources, resources)
      updateStore(
        chatRoomStore.setChatRooms,
        chatRooms?.map((_chatRoom) => ({
          ..._chatRoom,
          isCreatedFromPost: true,
        }))
      )
      updateStore(facilitiesStore.setFacilities, facilities)
      updateStore(societyContactsStore.setContacts, societyContacts)
      updateStore(societyBoardroomContactsStore.setContacts, boardRoomContacts)
      updateStore(groupStore.setGroups, groups)
      updateStore(localOffersStore.setLocalOffers, localOffers)

      self.setPosts(processedPosts)
    },
  }))
  .actions((self) => ({
    getFeed: flow(function* getFeed(fetchNextPage = false) {
      self.fetchingFeed = 'pending'
      try {
        let query = fetchNextPage
          ? { lastKnownDate: self.lastKnownFeedDate }
          : {}
        if (self.societyFilters.length > 0) {
          query = {
            ...query,
            // Backend does not accept properly encoded arrays
            // @ts-ignore
            societiesIds: `${self.societyFilters.join(';')}`,
          }
        }
        const resp = yield apiGetFeed(query)
        const data = resp.data as NPosts.NGetFeed.IResponse
        const posts = data.data
        // @ts-ignore
        const comments = data?.computed?.comments as ICommentModel[] | undefined
        const {
          users,
          media,
          documents,
          polls,
          societies,
          events,
          resources,
          chat,
          facilities,
          groups,
        } = data.populated
        const societyContacts = data.populated['society-contacts']
        const boardroomContacts = data.populated['boardroom-contacts']
        const boardroomEvents = data.populated['boardroom-events']
        const localOffers = data.populated['local-offers']

        self.processPosts(
          posts,
          comments,
          users,
          media,
          documents,
          polls,
          events,
          boardroomEvents,
          societies,
          resources,
          chat,
          facilities,
          societyContacts,
          boardroomContacts,
          groups,
          localOffers
        )

        self.setHasFetchedPostsOnce(true)

        self.fetchingFeed = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingFeed = 'error'
      }
    }),
    getPost: flow(function* getPost(id: string) {
      self.fetchingPostState.set(id, 'pending')
      try {
        const resp = yield apiGetPost(id)
        const data = resp.data as NPosts.NGetById.IResponse
        const post = data.data
        const comments = data?.computed?.comments as ICommentModel[] | undefined
        const {
          users,
          media,
          documents,
          polls,
          societies,
          events,
          resources,
          chat,
          facilities,
          groups,
        } = data.populated
        const societyContacts = data.populated['society-contacts']
        const boardroomContacts = data.populated['boardroom-contacts']
        const boardroomEvents = data.populated['boardroom-events']

        self.processPosts(
          [post],
          comments,
          users,
          media,
          documents,
          polls,
          events,
          boardroomEvents,
          societies,
          resources,
          chat,
          facilities,
          societyContacts,
          boardroomContacts,
          groups
        )

        self.fetchingPostState.set(id, 'done')
        return post
      } catch (error) {
        const code = error?.response?.data?.code
        if (code !== 404) {
          captureException(error)
        }
        self.fetchingPostState.set(id, 'error')
        return false
      }
    }),
    toggleLike: flow(function* toggleLike(id: string, like: boolean) {
      // Like immediately
      const { authenticationStore } = getRoot<RootStore>(self)
      const _post = self.posts.get(id)

      _post?.toggleLike(authenticationStore.userId as string, like)

      try {
        yield apiToggleLike(id, like)
      } catch (error) {
        captureException(error)
        // Remove immediate like if failed
        _post?.toggleLike(authenticationStore.userId as string, !like)
      }
    }),
    remove: flow(function* remove(id: string) {
      try {
        yield apiRemove(id)
        self.posts.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    createPost: flow(function* create(body: NPosts.NCreate.IRequestBody) {
      self.creatingPost = 'pending'
      try {
        const resp = yield apiCreate(body)
        const data = resp.data as NPosts.NCreate.IResponse
        const post = data.data

        if (post !== null) {
          self.processPosts([post])
        }

        self.creatingPost = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingPost = 'error'
        return false
      }
    }),
    patchPost: flow(function* patchPost(
      id: string,
      body: NPosts.NPatch.IRequestBody
    ) {
      self.updatingPost = 'pending'
      try {
        const resp = yield apiUpdate(id, body)
        const data = resp.data as NPosts.NPatch.IResponse
        const post = data.data

        const _oldPost = self.posts.get(id)

        if (post !== null) {
          Object.assign(_oldPost as object, post)
        }

        self.updatingPost = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingPost = 'error'
        return false
      }
    }),
    reportPost: flow(function* patchPost(id: string, message: string) {
      self.reportingPost = 'pending'
      try {
        yield apiReport(id, { message })
        self.reportingPost = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.reportingPost = 'error'
        return false
      }
    }),
  }))
