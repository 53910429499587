import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays } from 'date-fns'
import { SnapshotOut } from 'mobx-state-tree'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useStores } from '../../../../hooks/useStores'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { societyFacilityCreateSchema } from '../../../../forms/schemas/society_facility_create'
import { FacilityModel } from '../../../../state/models/facility'
import {
  formatDateWithFormat,
  formatDateWithoutTime,
  ignoreTimezoneDate,
} from '../../../../helpers/date'
import { weeklySchedule } from './weeklySchedule'
import { Toggle } from '../../../common/Toggle'
import { Icon, IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'
import { HorizontalFormSection } from '../../../common/HorizontalFormSection'
import { DropZone, DropZoneVariant } from '../../../common/DropZone'
import { MediaImage } from '../../../common/Image'
import { TextInput } from '../../../common/TextInput'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useDropdownOptions } from './useDropdownOptions'
import { DateTimeInput } from '../../../common/DateTimeInput'
import { Checkbox } from '../../../common/Checkbox'
import { Label } from '../../../common/Label'
import { TextArea } from '../../../common/TextArea'
import { useModal } from '../../../../hooks/useModal'
import { NotificationEmailsModal } from './NotificationEmailsModal'
import { DateTimeInputMultiple } from '../../../common/DateTimeInputMultiple'
import { WeeklyScheduleModal } from './WeeklyScheduleModal'
// eslint-disable-next-line max-len
import { weeklySchedule as weeklyScheduleSchema } from '../../../../forms/schemas/society_facility_weekly_schedule_create'
import { useWeekdays } from './useWeekdays'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../helpers/notification_settings_options'

interface CreateUpdateFacilityFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyFacilityCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  facility?: SnapshotOut<typeof FacilityModel>
}

export const CreateUpdateFacilityForm = observer(
  ({
    facility,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateFacilityFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, mediaStore, bookingsStore } = useStores()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const weekdays = useWeekdays()
    const { bookingGranularityOptions, rulesOptions, visibilityOptions } =
      useDropdownOptions()
    const {
      show: showEmailNotificationModal,
      open: openEmailNotificationModal,
      close: closeEmailNotificationModal,
    } = useModal()
    const {
      show: showWeeklyScheduleModal,
      open: openWeeklyScheduleModal,
      close: closeWeeklyScheduleModal,
    } = useModal()

    const updateMode = !!facility

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (!isAdmin || (updateMode && facility === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getBookingAvailability = useCallback((): {
      startDate: string | null
      endDate: string | null
    } | null => {
      const bookingAvailabilityArray = facility?.bookingAvailability
      if (updateMode && bookingAvailabilityArray) {
        const bookingAvailability =
          bookingAvailabilityArray.length > 0
            ? bookingAvailabilityArray[0]
            : undefined
        if (bookingAvailability) {
          return bookingAvailability
        }
      }

      return null
    }, [facility?.bookingAvailability, updateMode])

    const getWeeklyScheduleLabel = (
      _weeklySchedule: InferType<typeof weeklyScheduleSchema>
    ): string => {
      if (!_weeklySchedule) {
        return `${translate('common.actions.change')}`
      }

      return _weeklySchedule
        .map((_day, index) => {
          if (_day.allowed) {
            return `${weekdays[index]} ${formatDateWithFormat(
              ignoreTimezoneDate(new Date(_day.startTime)),
              'HH:mm'
            )}-${formatDateWithFormat(
              ignoreTimezoneDate(new Date(_day.endTime)),
              'HH:mm'
            )}`
          }
          return undefined
        })
        .filter((_val) => _val !== undefined)
        .join(', ')
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyFacilityCreateSchema
    > => {
      const bookingAvailability = getBookingAvailability()
      const bookingAvailabiltyStartDate = bookingAvailability?.startDate
        ? new Date(
            Math.max(
              ...[
                new Date(bookingAvailability.startDate).getTime(),
                new Date().getTime(),
              ]
            )
          )
        : new Date()

      return {
        booking: updateMode ? facility?.booking : true,
        name: updateMode ? facility?.name || '' : '',
        description: updateMode ? facility?.description || '' : '',
        societyId: updateMode ? facility.societyId || society._id : society._id,
        coverPhotoId: updateMode ? facility?.coverPhotoId : undefined,
        notificationSettings: updateMode
          ? (facility?.notificationSettings as NOTIFICATION_SETTINGS_VALUE) ||
            NOTIFICATION_SETTINGS_VALUE.NONE
          : NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED,
        bookingGranularity: updateMode
          ? facility?.bookingGranularity || '1hr'
          : '1hr',
        rules: updateMode ? facility?.rules || 'one-active' : 'one-active',
        visibility: updateMode ? facility?.visibility || 'all' : 'all',
        userId: updateMode
          ? facility?.userId || undefined
          : authenticationStore.userId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        weeklySchedule: updateMode
          ? facility?.weeklySchedule || weeklySchedule
          : weeklySchedule,
        bookingMessage: updateMode ? facility?.bookingMessage || '' : '',
        bookingAvailabilityStartDate: bookingAvailabiltyStartDate.toString(),
        bookingAvailabilityEndDate: updateMode
          ? bookingAvailability?.endDate
          : undefined,
        bookableUntilFurtherNotice:
          bookingAvailability?.endDate === undefined ||
          bookingAvailability?.endDate === null,
        notificationEmails: updateMode
          ? facility?.notificationEmails || []
          : [],
        blockedDates: updateMode
          ? // Don't save past blockedDates
            facility?.blockedDates?.filter(
              (_date) =>
                formatDateWithFormat(new Date(_date), 'yyyy-MM-dd') >=
                formatDateWithFormat(new Date(), 'yyyy-MM-dd')
            ) || []
          : [],
      }
    }, [
      society,
      facility,
      updateMode,
      authenticationStore?.userId,
      getBookingAvailability,
    ])

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyFacilityCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const uploadImage = async (base64: string): Promise<void> => {
      setUploadingPicture(true)

      try {
        const mediaId = await mediaStore.createMedia('image', base64)
        if (!mediaId) {
          throw new Error('no mediaId returned by createMedia')
        }

        setValue('coverPhotoId', mediaId, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    const watchBookableUntilFurtherNotice = watch('bookableUntilFurtherNotice')
    const watchBookingAvailabilityStartDate = watch(
      'bookingAvailabilityStartDate'
    )
    const watchBookingAvailabilityEndDate = watch('bookingAvailabilityEndDate')
    const watchNotificationEmails = watch('notificationEmails')
    const watchBlockedDates = watch('blockedDates')
    const watchWeeklySchedule = watch('weeklySchedule')

    const onChangeNotificationEmails = (emails: string[]): void => {
      setValue('notificationEmails', emails, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }

    const onChangeBlockedDates = (dates: Date[]): void => {
      const stringDates = dates.map((_date) => _date.toUTCString())
      setValue('blockedDates', stringDates, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }

    const onChangeWeeklySchedule = (
      schedule: InferType<typeof weeklyScheduleSchema>
    ): void => {
      setValue('weeklySchedule', schedule, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }

    const now = new Date()
    const hasActiveBookings = facility?._id
      ? bookingsStore
          .bookingsForFacility(facility?._id)
          .some((_booking) => new Date(_booking.startDate) > now)
      : false

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full">
          <HorizontalFormSection
            title={translate('createUpdateFacility.form.headers.general')}
          >
            <div className="flex flex-col space-y-6">
              <Controller
                control={control}
                name="booking"
                render={({ field: { onChange, value, name } }) => (
                  <div className="flex items-center space-x-6">
                    <Icon icon={IconChoices.CALENDAR} />
                    <p style={theme.textVariants.baseBold}>
                      {translate('createUpdateFacility.form.labels.booking')}
                    </p>
                    <Toggle name={name} enabled={value} onChange={onChange} />
                  </div>
                )}
              />
              <Controller
                control={control}
                render={({ field: { value, name } }) => {
                  return (
                    <FormControl
                      label={translate(
                        'createUpdateFacility.form.labels.coverPhoto'
                      )}
                      name={name}
                    >
                      {value ? (
                        <div className="flex w-[343px] justify-center md:w-full">
                          <MediaImage
                            mediaId={value}
                            size={96}
                            onDeleteClick={() =>
                              setValue(name, '', {
                                shouldValidate: true,
                                shouldDirty: true,
                              })
                            }
                            objectFit="object-contain"
                          />
                        </div>
                      ) : (
                        <DropZone
                          loading={uploadingPicture}
                          onUpload={(status, file) => {
                            if (status === 'accepted') {
                              uploadImage(file.base64)
                            } else {
                              setToastNotification(
                                ToastType.DANGER,
                                translate(
                                  'flashMessage.somethingWentWrongError'
                                )
                              )
                            }
                          }}
                          variant={DropZoneVariant.MEDIA}
                        />
                      )}
                    </FormControl>
                  )
                }}
                name="coverPhotoId"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormControl
                    label={translate('createUpdateFacility.form.labels.name')}
                    error={errors.name && getErrorMessage(errors.name)}
                    name={name}
                  >
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.name}
                    />
                  </FormControl>
                )}
                name="name"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormControl
                    label={translate(
                      'createUpdateFacility.form.labels.description'
                    )}
                    error={
                      errors.description && getErrorMessage(errors.description)
                    }
                    name={name}
                  >
                    <TextArea
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.description}
                    />
                  </FormControl>
                )}
                name="description"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    error={
                      errors.visibility && getErrorMessage(errors.visibility)
                    }
                    name={name}
                    label={translate(
                      'createUpdateFacility.form.labels.visibility'
                    )}
                  >
                    <SelectDropdown
                      options={visibilityOptions}
                      value={value}
                      onChange={onChange}
                      error={!!errors.visibility}
                    />
                  </FormControl>
                )}
                name="visibility"
              />
              <Controller
                control={control}
                name="notificationSettings"
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    label={translate('common.notificationSettings.label')}
                    name={name}
                    error={
                      errors.notificationSettings &&
                      getErrorMessage(errors.notificationSettings)
                    }
                  >
                    <SelectDropdown
                      value={value}
                      onChange={onChange}
                      options={notificationSettingsOptions}
                      error={!!errors.notificationSettings}
                    />
                  </FormControl>
                )}
              />
            </div>
          </HorizontalFormSection>
          <HorizontalFormSection
            title={translate('createUpdateFacility.form.headers.details')}
          >
            <div className="flex flex-col space-y-6">
              <Controller
                control={control}
                name="bookingGranularity"
                render={({ field: { value, name, onChange } }) => (
                  <FormControl
                    error={
                      errors.bookingGranularity &&
                      getErrorMessage(errors.bookingGranularity)
                    }
                    name={name}
                    label={translate(
                      'createUpdateFacility.form.labels.bookingGranularity'
                    )}
                    helperText={
                      hasActiveBookings
                        ? translate(
                            'createUpdateFacility.form.warnings.bookingGranularity.cantEdit'
                          )
                        : undefined
                    }
                  >
                    <SelectDropdown
                      options={bookingGranularityOptions}
                      value={value}
                      onChange={onChange}
                      error={!!errors.bookingGranularity}
                      disabled={hasActiveBookings}
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    error={errors.rules && getErrorMessage(errors.rules)}
                    name={name}
                    label={translate('createUpdateFacility.form.labels.rules')}
                  >
                    <SelectDropdown
                      options={rulesOptions}
                      value={value}
                      onChange={onChange}
                      error={!!errors.rules}
                    />
                  </FormControl>
                )}
                name="rules"
              />
              <Controller
                control={control}
                render={({ field: { value, name } }) => {
                  return (
                    <FormControl
                      name={name}
                      label={translate(
                        'createUpdateFacility.form.labels.weeklySchedule'
                      )}
                      helperText={
                        hasActiveBookings
                          ? translate(
                              'createUpdateFacility.form.warnings.bookingGranularity.cantEdit'
                            )
                          : undefined
                      }
                    >
                      <Button
                        disabled={hasActiveBookings}
                        wrapperClassName="w-full overflow-hidden"
                        className="w-full"
                        onClick={openWeeklyScheduleModal}
                        label={
                          <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                            {getWeeklyScheduleLabel(value)}
                          </div>
                        }
                      />
                    </FormControl>
                  )
                }}
                name="weeklySchedule"
              />
              <Controller
                control={control}
                name="bookingAvailabilityStartDate"
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    label={translate(
                      'createUpdateFacility.form.labels.bookableFrom'
                    )}
                    error={
                      errors.bookingAvailabilityStartDate &&
                      getErrorMessage(errors.bookingAvailabilityStartDate)
                    }
                    name={name}
                  >
                    <DateTimeInput
                      onChange={(val) => {
                        onChange(val)
                        if (
                          watchBookingAvailabilityEndDate &&
                          new Date(watchBookingAvailabilityEndDate) <
                            addDays(new Date(val), 30)
                        ) {
                          setValue(
                            'bookingAvailabilityEndDate',
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            addDays(new Date(val), 30)
                          )
                        }
                      }}
                      minDate={new Date()}
                      value={value ? new Date(value) : new Date()}
                    />
                  </FormControl>
                )}
              />
              <div className="flex flex-col space-y-3">
                <Controller
                  control={control}
                  name="bookingAvailabilityEndDate"
                  render={({ field: { onChange, value, name } }) => (
                    <FormControl
                      label={translate(
                        'createUpdateFacility.form.labels.bookableTo'
                      )}
                      error={
                        errors.bookingAvailabilityEndDate &&
                        getErrorMessage(errors.bookingAvailabilityEndDate)
                      }
                      name={name}
                    >
                      {watchBookableUntilFurtherNotice ? (
                        <TextInput
                          value={translate(
                            'createUpdateFacility.form.labels.bookableUntilFurtherNotice'
                          )}
                          onChange={() => null}
                          onBlur={() => null}
                          disabled
                        />
                      ) : (
                        <DateTimeInput
                          onChange={(val) => {
                            // For some ungodly reason backend has a minimum span of 30 days
                            const minVal = addDays(
                              new Date(
                                watchBookingAvailabilityStartDate as string
                              ),
                              30
                            )
                            if (new Date(val) < minVal) {
                              onChange(minVal)
                            } else {
                              onChange(val)
                            }
                          }}
                          minDate={addDays(
                            new Date(
                              watchBookingAvailabilityStartDate as string
                            ),
                            30
                          )}
                          value={value ? new Date(value) : new Date()}
                        />
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="bookableUntilFurtherNotice"
                  render={({ field: { name, value, onChange } }) => (
                    <div className="flex space-x-2">
                      <Checkbox
                        name={name}
                        onChange={onChange}
                        value={value}
                        type="checkbox"
                      />
                      <Label
                        name={name}
                        label={translate(
                          'createUpdateFacility.form.labels.bookableUntilFurtherNotice'
                        )}
                        className="cursor-pointer"
                        style={theme.textVariants.captionBold}
                      />
                    </div>
                  )}
                />
              </div>
              <Controller
                control={control}
                render={({ field: { value, name } }) => {
                  const blockedDates =
                    value
                      ?.filter((_val) => _val !== undefined)
                      .map((_date) => _date && new Date(_date)) || []
                  return (
                    <FormControl
                      name={name}
                      label={translate(
                        'createUpdateFacility.form.labels.blockedDates'
                      )}
                    >
                      <DateTimeInputMultiple
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        highlightedDays={blockedDates}
                        onChange={(dates) => onChangeBlockedDates(dates)}
                        minDate={new Date()}
                      />
                    </FormControl>
                  )
                }}
                name="blockedDates"
              />
              {watchBlockedDates && watchBlockedDates.length > 0 && (
                <div className="w-full space-y-2">
                  {watchBlockedDates.map((_blockedDate) => {
                    return (
                      <div
                        className="flex w-full items-center gap-2"
                        key={_blockedDate}
                      >
                        <div className="flex-1 rounded border p-2 text-center">
                          {_blockedDate &&
                            formatDateWithoutTime(Date.parse(_blockedDate))}
                        </div>
                        <Button
                          onClick={() =>
                            setValue(
                              'blockedDates',
                              watchBlockedDates?.filter(
                                (_date) => _date !== _blockedDate
                              ),
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            )
                          }
                          icon={IconChoices.DELETE_TRASH}
                          size="sm"
                          variant={ButtonVariant.DANGER}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </HorizontalFormSection>
          <HorizontalFormSection
            title={translate(
              'createUpdateFacility.form.headers.notificationSettings'
            )}
          >
            <div className="flex flex-col space-y-6">
              <Controller
                control={control}
                name="bookingMessage"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <FormControl
                    name={name}
                    label={translate(
                      'createUpdateFacility.form.labels.bookingMessage'
                    )}
                    error={
                      errors.bookingMessage &&
                      getErrorMessage(errors.bookingMessage)
                    }
                  >
                    <TextArea
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.bookingMessage}
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                render={({ field: { value, name } }) => {
                  const valueString =
                    value && value.length > 0
                      ? value?.join(', ')
                      : translate('common.actions.change')

                  return (
                    <FormControl
                      error={
                        errors.visibility && getErrorMessage(errors.visibility)
                      }
                      name={name}
                      label={translate(
                        'createUpdateFacility.form.labels.notificationEmails'
                      )}
                      className="w-[343px] md:w-full"
                    >
                      <Button
                        wrapperClassName="w-full"
                        className="w-full"
                        onClick={openEmailNotificationModal}
                        label={
                          <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                            {valueString}
                          </div>
                        }
                      />
                    </FormControl>
                  )
                }}
                name="notificationEmails"
              />
            </div>
          </HorizontalFormSection>
          <HorizontalFormSection title="">
            <Button
              wrapperClassName="flex justify-end"
              loading={loading}
              disabled={!isValid || loading}
              label={translate('common.actions.save')}
              variant={ButtonVariant.PRIMARY}
              type="submit"
            />
          </HorizontalFormSection>
        </form>
        <WeeklyScheduleModal
          show={showWeeklyScheduleModal}
          close={closeWeeklyScheduleModal}
          weeklySchedule={watchWeeklySchedule}
          onChange={onChangeWeeklySchedule}
        />
        <NotificationEmailsModal
          show={showEmailNotificationModal}
          close={closeEmailNotificationModal}
          emails={watchNotificationEmails}
          onChange={onChangeNotificationEmails}
        />
      </>
    )
  }
)
