import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SocietyContactModel } from '../../../../state/models/society-contact'
import { societyContactCreateSchema } from '../../../../forms/schemas/society_contact_create'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { DropdownSelect } from '../../../common/DropdownSelect'
import { TextInput } from '../../../common/TextInput'
import { TextArea } from '../../../common/TextArea'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../helpers/notification_settings_options'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { DropZone, DropZoneVariant } from '../../../common/DropZone'
import { MediaImage } from '../../../common/Image'
import { theme } from '../../../../theme/theme'

interface CreateUpdateContactFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyContactCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  contact?: SnapshotOut<typeof SocietyContactModel>
  isBoardMember?: boolean
}

export const CreateUpdateContactForm = observer(
  ({
    contact,
    loading,
    isBoardMember,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateContactFormProps): JSX.Element => {
    const { mediaStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!contact

    const getDefaultValues = useCallback((): InferType<
      typeof societyContactCreateSchema
    > => {
      return {
        name: updateMode ? contact?.name || '' : '',
        title: updateMode ? contact?.title || '' : '',
        description: updateMode ? contact?.description || '' : '',
        phone: updateMode ? contact?.phone : '',
        phoneCountry: '+46',
        email: updateMode ? contact?.email || '' : undefined,
        website: updateMode ? contact?.website || '' : undefined,
        mediaId: updateMode ? contact?.mediaId || undefined : undefined,
        societyId: updateMode ? contact?.societyId || society._id : society._id,
        notificationSettings: updateMode
          ? contact?.notificationSettings ||
            NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED
          : NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED,
      }
    }, [society, contact, updateMode])

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyContactCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const uploadImage = async (base64: string): Promise<void> => {
      setUploadingPicture(true)

      try {
        const mediaId = await mediaStore.createMedia('image', base64)
        if (!mediaId) {
          throw new Error('no mediaId returned by createMedia')
        }

        setValue('mediaId', mediaId, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    return (
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          render={({ field: { value, name } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.coverPhoto')}
              name={name}
            >
              {value ? (
                <div className="flex h-1/3 w-1/3">
                  <MediaImage
                    mediaId={value}
                    onDeleteClick={() =>
                      setValue(name, null, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                    objectFit="object-contain"
                  />
                </div>
              ) : (
                <DropZone
                  loading={uploadingPicture}
                  onUpload={(status, file) => {
                    if (status === 'accepted') {
                      uploadImage(file.base64)
                    } else {
                      setToastNotification(
                        ToastType.DANGER,
                        translate('flashMessage.somethingWentWrongError')
                      )
                    }
                  }}
                  variant={DropZoneVariant.MEDIA}
                />
              )}
            </FormControl>
          )}
          name="mediaId"
        />
        {!updateMode && (
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                // eslint-disable-next-line max-len
                label={`${translate(isBoardMember ? 'common.notificationSettings.labelBoard' : 'common.notificationSettings.label')}`}
                error={
                  errors.notificationSettings &&
                  getErrorMessage(errors.notificationSettings)
                }
                name={name}
              >
                <DropdownSelect
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                />
              </FormControl>
            )}
            name="notificationSettings"
          />
        )}
        <Controller
          control={control}
          name="name"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.name')}
              error={errors.name && getErrorMessage(errors.name)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.title')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.description')}
              name={name}
              error={errors.description && getErrorMessage(errors.description)}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.description}
              />
            </FormControl>
          )}
        />
        <p style={theme.textVariants.base} className="mt-2">
          {translate('createUpdateContactForm.headers.contactDetails')}
        </p>
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.phoneNumber')}
              name={name}
              error={errors.phone && getErrorMessage(errors.phone)}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.phone}
                type="tel"
                // TODO: add support to display this textLeft="SE +46"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.emailAddress')}
              name={name}
              error={errors.email && getErrorMessage(errors.email)}
            >
              <TextInput
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.email}
                type="email"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="website"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContactForm.labels.website')}
              name={name}
              error={errors.website && getErrorMessage(errors.website)}
            >
              <TextInput
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.website}
              />
            </FormControl>
          )}
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            label={translate(
              updateMode ? 'common.actions.save' : 'common.actions.add'
            )}
            type="submit"
            variant={ButtonVariant.PRIMARY}
          />
        </div>
      </form>
    )
  }
)
