import * as yup from 'yup'

export const societyManagementSettingsSchema = yup
  .object({
    postingDisabled: yup.boolean(),
    marketplacePostingDisabled: yup.boolean(),
    chatOneOnOneDisabled: yup.boolean(),
    chatInterestsDisabled: yup.boolean(),
    chatAskBoardDisabled: yup.boolean(),
    chatAskBoardDisabledResidentMessage: yup.string(),
  })
  .required()
