import { AxiosResponse } from 'axios'
import { NPosts } from '../interfaces/services/posts.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const POST_POPULATE = [
  'comments',
  'users.media',
  'media',
  'documents',
  'polls',
  'societies',
  'societies.media',
  'societies.boardMembersList',
  'societies.adminsList',
  'comments',
  'comments.users',
  'comments.users.media',
  'events',
  'boardroomEvents',
  'resources.media',
  'chat',
  'chat.media',
  'chat.users',
  'facilities',
  'facilities.media',
  'contacts.media',
  'boardroomContacts.media',
  'groups',
  'localOfferIds.media',
]

export const getFeed = (
  query?: NPosts.NGetFeed.IRequestQuery
): Promise<AxiosResponse<NPosts.NGetFeed.IResponse>> => {
  return axiosAuth.get<NPosts.NGetFeed.IResponse>(
    createUrl('/posts/get-feed', {
      populate: POST_POPULATE.join(';'),
      ...query,
    })
  )
}

export const toggleLike = (
  id: string,
  like: boolean
): Promise<AxiosResponse<NPosts.NToggleLike.IResponse>> => {
  return axiosAuth.post<NPosts.NToggleLike.IResponse>(
    createUrl(`/posts/toggle-like/${id}`),
    {
      value: like,
    }
  )
}

export const getPost = (
  id: string
): Promise<AxiosResponse<NPosts.NGetById.IResponse>> => {
  return axiosAuth.get<NPosts.NGetById.IResponse>(
    createUrl(`/posts/post/${id}`, {
      populate: POST_POPULATE.join(';'),
    })
  )
}

export const remove = (
  id: string
): Promise<AxiosResponse<NPosts.NDel.IResponse>> => {
  return axiosAuth.delete<NPosts.NDel.IResponse>(createUrl(`/posts/post/${id}`))
}

export const create = (
  body: NPosts.NCreate.IRequestBody
): Promise<AxiosResponse<NPosts.NCreate.IResponse>> => {
  return axiosAuth.post<NPosts.NCreate.IResponse>(
    createUrl('/posts/post'),
    body
  )
}

export const update = (
  id: string,
  body: NPosts.NPatch.IRequestBody
): Promise<AxiosResponse<NPosts.NPatch.IResponse>> => {
  return axiosAuth.patch<NPosts.NPatch.IResponse>(
    createUrl(`/posts/post/${id}`),
    body
  )
}

export const report = (
  id: string,
  body: NPosts.NReport.IRequestBody
): Promise<AxiosResponse<NPosts.NReport.IResponse>> => {
  return axiosAuth.post<NPosts.NReport.IResponse>(
    createUrl(`/posts/report/${id}`),
    body
  )
}
