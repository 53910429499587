import { AxiosResponse } from 'axios'
import { NEvents } from '../interfaces/services/events.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const getEventsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NEvents.NGetPerSocieties.IResponse>> => {
  return axiosAuth.get<NEvents.NGetPerSocieties.IResponse>(
    createUrl(`/events/get-per-society/${societyId}`)
  )
}

export const getEvent = (
  id: string
): Promise<AxiosResponse<NEvents.NGetById.IResponse>> => {
  return axiosAuth.get<NEvents.NGetById.IResponse>(
    createUrl(`/events/event/${id}`)
  )
}

export const createEvent = (
  body: NEvents.NCreate.IRequestBody
): Promise<AxiosResponse<NEvents.NCreate.IResponse>> => {
  return axiosAuth.post<NEvents.NCreate.IResponse>(
    createUrl(`/events/event`),
    body
  )
}

export const updateEvent = (
  id: string,
  body: NEvents.NPatch.IRequestBody
): Promise<AxiosResponse<NEvents.NPatch.IResponse>> => {
  return axiosAuth.patch<NEvents.NPatch.IResponse>(
    createUrl(`/events/event/${id}`),
    body
  )
}

export const deleteEvent = (
  id: string,
  queryParameters: NEvents.NDel.IRequestQuery
): Promise<AxiosResponse<NEvents.NDel.IResponse>> => {
  return axiosAuth.delete<NEvents.NDel.IResponse>(
    createUrl(`/events/event/${id}`, { ...queryParameters })
  )
}

export const eventAttendance = (
  id: string,
  body: NEvents.NAttendance.IRequestBody
): Promise<AxiosResponse<NEvents.NAttendance.IResponse>> => {
  return axiosAuth.post<NEvents.NAttendance.IResponse>(
    createUrl(`/events/event/${id}/attendance`),
    body
  )
}
