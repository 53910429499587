import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { Divider } from '../../common/Divider'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { PostBoardroomEvent } from '../PostBoardroomEvent'

interface PostBoardroomEventsProps {
  boardroomEventsIds: string[]
  disabled?: boolean
}

export const PostBoardroomEvents = observer(
  ({
    boardroomEventsIds,
    disabled,
  }: PostBoardroomEventsProps): JSX.Element | null => {
    const { boardRoomEventStore } = useStores()

    const boardRoomEvents = boardroomEventsIds
      .map((_id): SnapshotOut<typeof BoardRoomEventModel> | undefined =>
        boardRoomEventStore.events.get(_id)
      )
      .filter(
        (value): value is SnapshotOut<typeof BoardRoomEventModel> => !!value
      )

    if (boardRoomEvents.length === 0) {
      return null
    }

    return (
      <>
        <Divider />
        {boardRoomEvents.map((event) => (
          <div key={event._id} className="flex flex-col">
            <div className="my-0 border-b border-neutral-80 first:hidden" />
            <PostBoardroomEvent
              key={event._id}
              event={event}
              disabled={disabled}
            />
          </div>
        ))}
      </>
    )
  }
)
