/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { IBoardRoomEventModel } from '../../interfaces/models/boardroom-events.interfaces'
import {
  getBoardRoomEventsPerSociety as apiGetBoardRoomEventsPerSociety,
  createBoardRoomEvent as apiCreateBoardRoomEvent,
  updateBoardRoomEvent as apiUpdateBoardRoomEvent,
  getBoardRoomEvent as apiGetBoardRoomEvent,
  deleteBoardRoomEvent as apiDeleteBoardRoomEvent,
  boardRoomEventAttendance as apiBoardRoomEventAttendance,
} from '../../api/boardroom-events'
import { NBoardRoomEvents } from '../../interfaces/services/boardroom-events.interfaces'
import { BoardRoomEventModel } from '../models/boardroom-event'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { IEventAttendanceStatus } from '../../interfaces/models/events.interfaces'
import { RootStore } from './root'

export const BoardRoomEventStore = types
  .model('BoardRoomEventStore')
  .props({
    events: types.map(BoardRoomEventModel),
    fetchingEvents: stateType,
    creatingEvent: stateType,
    updatingEvent: stateType,
    updatingEventAttendance: stateType,
  })
  .views((self) => ({
    get sortedEvents(): SnapshotOut<typeof BoardRoomEventModel>[] {
      return (
        // @ts-ignore
        (values(self.events) as SnapshotOut<typeof BoardRoomEventModel>[]).sort(
          (a, b) => sortByDate(a.startDate, b.startDate, true)
        )
      )
    },
  }))
  .views((self) => ({
    eventsForSociety(
      societyId: string
    ): SnapshotOut<typeof BoardRoomEventModel>[] {
      return self.sortedEvents.filter(
        (event: SnapshotOut<typeof BoardRoomEventModel>) =>
          event.societyId === societyId
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.events.clear()
      self.fetchingEvents = 'none'
      self.creatingEvent = 'none'
      self.updatingEvent = 'none'
      self.updatingEventAttendance = 'none'
    },
    setEvents: (events: IBoardRoomEventModel[]) => {
      events.forEach((event) => {
        // @ts-ignore
        setObject(self.events, BoardRoomEventModel, {
          ...event,
          startDate: event.startDate as unknown as string,
          endDate: event.endDate as unknown as string,
          societyId: event.societyId,
        })
      })
    },
  }))
  .actions((self) => ({
    getEventsPerSociety: flow(function* getEventsPerSociety(societyId: string) {
      self.fetchingEvents = 'pending'
      try {
        const resp = yield apiGetBoardRoomEventsPerSociety(societyId)

        const data = resp.data as NBoardRoomEvents.NGetPerSocieties.IResponse

        const events = data.data

        self.setEvents(events)

        self.fetchingEvents = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingEvents = 'error'
      }
    }),
    createEvent: flow(function* createEvent(
      body: NBoardRoomEvents.NCreate.IRequestBody
    ) {
      self.creatingEvent = 'pending'
      try {
        const resp = yield apiCreateBoardRoomEvent(body)
        const data = resp.data as NBoardRoomEvents.NCreate.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.creatingEvent = 'done'
        return event
      } catch (error) {
        captureException(error)
        self.creatingEvent = 'error'
        return false
      }
    }),
    getEvent: flow(function* getEvent(id: string) {
      self.fetchingEvents = 'pending'
      try {
        const resp = yield apiGetBoardRoomEvent(id)
        const data = resp.data as NBoardRoomEvents.NGetById.IResponse
        const event = data.data

        self.setEvents([event])

        self.fetchingEvents = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingEvents = 'error'
      }
    }),
    patchEvent: flow(function* patchEvent(
      id: string,
      body: NBoardRoomEvents.NPatch.IRequestBody
    ) {
      self.updatingEvent = 'pending'
      try {
        const resp = yield apiUpdateBoardRoomEvent(id, body)
        const data = resp.data as NBoardRoomEvents.NPatch.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.updatingEvent = 'done'
        return event
      } catch (error) {
        captureException(error)
        self.updatingEvent = 'error'
        return false
      }
    }),
    deleteEvent: flow(function* deleteEvent(
      id: string,
      notificationSetting: 'none' | 'feed' | 'notification+feed'
    ) {
      try {
        yield apiDeleteBoardRoomEvent(id, { notificationSetting })

        const { postStore } = getRoot<RootStore>(self)
        const postsReferringEvent = postStore.sortedPosts
          .filter((_post) => _post.boardroomEventsIds.includes(id))
          .map((_post) => _post._id)
        postsReferringEvent.forEach((_id) => postStore.deletePost(_id))

        self.events.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    updateEventAttendance: flow(function* updateEventAttendance(
      id: string,
      status: IEventAttendanceStatus
    ) {
      self.updatingEventAttendance = 'pending'
      try {
        const resp = yield apiBoardRoomEventAttendance(id, { status })
        const data = resp.data as NBoardRoomEvents.NAttendance.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.updatingEventAttendance = 'done'
        return event
      } catch (error) {
        self.updatingEventAttendance = 'done'
        captureException(error)
        return false
      }
    }),
  }))
