import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Icon, IconChoices } from '../../common/Icon'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { EventAttendanceButtons } from '../../../views/society/EventDetailView/EventAttendanceButtons'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'

interface PostBoardroomEventAttendanceProps {
  event: SnapshotOut<typeof BoardRoomEventModel>
}

export const PostBoardroomEventAttendance = ({
  event,
}: PostBoardroomEventAttendanceProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const userId = useAuthenticatedUserId() as string

  const userAttendance = event?.attendance?.find(
    (attendance) => attendance.userId === userId
  )
  const userAttending = userAttendance?.status === 'attending'

  if (!userAttendance) {
    return <EventAttendanceButtons event={event} boardRoomEvent />
  }

  return (
    <div
      className={`flex w-fit items-center gap-1 rounded-md p-2 ${
        userAttending ? 'bg-brandGreen' : 'bg-red'
      }`}
    >
      <Icon
        icon={userAttending ? IconChoices.CHECK_CIRLE : IconChoices.CROSS}
        color="white"
      />
      <p className="text-white">
        {userAttending
          ? translate('common.eventActions.attendance.attending')
          : translate('common.eventActions.attendance.notAttending')}
      </p>
    </div>
  )
}
