import * as yup from 'yup'

export const societyEventRemoveSchema = yup
  .object({
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification+feed'])
      .required(),
  })
  .required()
