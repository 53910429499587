import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { ViewBase } from '../../../../components/common/ViewBase'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { IconChoices } from '../../../../components/common/Icon'
import { CreateReportProblemForm } from '../../../../components/society/ReportProblem/CreateReportProblemForm'
// eslint-disable-next-line max-len
import { ReportProblemSettingsModal } from '../../../../components/society/ReportProblem/ReportProblemSettingsModal'
import { societyReportCreateSchema } from '../../../../forms/schemas/society_report_create'
import { createReport } from '../../../../api/service-report'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { ErrorView } from '../../../error/ErrorView'
import { IllustrationChoices } from '../../../../components/common/Illustration'

export const ReportProblemView = observer((): JSX.Element => {
  const { societyContactsStore, authenticationStore } = useStores()
  const [sendingReport, setSendingReport] = useState(false)
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:report-problem', {
      '{{ societyName }}': society.name,
    })
  )
  const { setToastNotification } = useToastNotifications()
  const {
    show: showSettingsModal,
    open: openSettingsModal,
    close: closeSettingsModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    societyContactsStore.getContactsPerSociety(society._id)
  }, [societyContactsStore, society._id])

  const buttons = isAdmin
    ? [
        {
          variant: ButtonVariant.PRIMARY,
          icon: IconChoices.SETTINGS,
          onClick: openSettingsModal,
          label: translate('common.settings'),
        },
      ]
    : []

  const showErrorMessage = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('reportProblemView.flashMessage.createReportFailure')
    )
  }

  const onFormSubmit = async (
    data: InferType<typeof societyReportCreateSchema>,
    mediaIds: string[]
  ): Promise<boolean> => {
    setSendingReport(true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const status = await createReport({
      ...data,
      mediaIds,
    })

    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('reportProblemView.flashMessage.createReportSuccess')
      )
    } else {
      showErrorMessage()
    }
    setSendingReport(false)

    return !!status
  }

  const onFormError = (): void => {
    showErrorMessage()
  }

  const hasReportProblemEmail =
    society.reportProblemEmailCC && society.reportProblemEmailCC?.length > 0

  if (!hasReportProblemEmail)
    return (
      <>
        <ErrorView
          title={translate('reportProblemView.errorView.title')}
          subtitle={translate('reportProblemView.errorView.subtitle')}
          illustration={IllustrationChoices.EMPTY}
          buttons={[
            {
              id: 'mybutton',
              button: {
                variant: ButtonVariant.PRIMARY,
                label: translate('common.settings'),
                onClick: openSettingsModal,
                icon: IconChoices.SETTINGS,
              },
            },
          ]}
        />
        {isAdmin && (
          <ReportProblemSettingsModal
            show={showSettingsModal}
            close={closeSettingsModal}
          />
        )}
      </>
    )

  return (
    <ErrorBoundary>
      <ViewBase title={translate('reportProblemView.title')} buttons={buttons}>
        <CreateReportProblemForm
          onSubmit={onFormSubmit}
          onError={onFormError}
          loading={sendingReport}
        />
      </ViewBase>
      {isAdmin && (
        <ReportProblemSettingsModal
          show={showSettingsModal}
          close={closeSettingsModal}
        />
      )}
    </ErrorBoundary>
  )
})
