import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../../common/Button'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { TextInput } from '../../../../common/TextInput'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../../common/FormControl'
import { societyRequestSchema } from '../../../../../forms/schemas/society_request'
import { RequestModel } from '../../../../../state/models/request'
import { useStores } from '../../../../../hooks/useStores'
import { UnitRole } from '../../../../../types/unit-roles'
import { UserModel } from '../../../../../state/models/user'
import { FormSpacing } from '../../../../common/FormSpacing'
import { getUnitRole } from '../../../../../helpers/translations/unit-roles'
import { SelectDropdown, SelectOption } from '../../../../common/SelectDropdown'
import { TextArea } from '../../../../common/TextArea'

interface RequestFormProps {
  onError: () => void
  onApprove: (data: InferType<typeof societyRequestSchema>) => Promise<void>
  onDeny: () => Promise<void>
  loading: boolean
  request: SnapshotOut<typeof RequestModel>
}

export const RequestForm = observer(
  ({
    request,
    loading,
    onError,
    onApprove,
    onDeny,
  }: RequestFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { unitStore, userStore, societyEntrancesStore } = useStores()
    const { getErrorMessage } = useFormErrorMessage()
    const user = userStore.users.get(request.userId) as SnapshotOut<
      typeof UserModel
    >

    const requestUnit =
      request && request.unitId
        ? unitStore.units.get(request.unitId)
        : undefined

    const getDefaultValues = useCallback((): InferType<
      typeof societyRequestSchema
    > => {
      return {
        unitId: request?.unitId || '',
        entranceId: requestUnit?.entranceId || '',
        role: request?.role || UnitRole.MEMBER,
      }
    }, [request, requestUnit])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
      setValue,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyRequestSchema),
      defaultValues: getDefaultValues(),
    })

    const watchEntranceId = watch('entranceId')

    const entrances = society
      ? societyEntrancesStore.entrancesForSociety(society._id)
      : []

    const entranceOptions = (): SelectOption[] =>
      entrances.map((entrance) => ({
        label: entrance.addressStreet as string,
        value: entrance._id,
      }))

    const entranceUnitOptions = (): SelectOption[] =>
      society
        ? unitStore
            .unitsForSociety(society._id)
            .filter((unit) => unit.entranceId === watchEntranceId)
            .map((unit) => {
              if (unit.isUnassigned) {
                return {
                  value: unit._id,
                  label: translate('common.unit.unassigned'),
                }
              }
              return {
                value: unit._id,
                label: `${unit.titleLegal} (${unit.title})`,
              }
            })
        : []

    const unitRoleOptions = (): SelectOption[] =>
      Object.values(UnitRole).map((val) => ({
        value: val as string,
        label: translate(getUnitRole(val) as string),
      }))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    return (
      <FormSpacing>
        <FormControl
          label={translate('common.form.labels.name')}
          name={translate('common.form.labels.name')}
        >
          <TextInput
            value={user.fullName}
            disabled
            onChange={() => null}
            onBlur={() => null}
          />
        </FormControl>
        <FormControl
          label={translate('common.form.labels.email')}
          name={translate('common.form.labels.email')}
        >
          <TextInput
            value={user.email}
            disabled
            onChange={() => null}
            onBlur={() => null}
          />
        </FormControl>
        {request.message && (
          <FormControl
            label={translate('common.form.labels.message')}
            name={translate('common.form.labels.message')}
          >
            <TextArea
              value={request.message}
              disabled
              onChange={() => null}
              onBlur={() => null}
            />
          </FormControl>
        )}
        <Controller
          control={control}
          name="entranceId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerView.unit.labels.entranceSamf'
                  : 'registerView.unit.labels.entrance'
              )}
              error={errors.entranceId && getErrorMessage(errors.entranceId)}
              name={name}
            >
              <SelectDropdown
                options={entranceOptions()}
                value={value}
                onChange={(val) => {
                  onChange(val)
                  setValue('unitId', '', { shouldValidate: true })
                }}
                error={!!errors.entranceId}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="unitId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'common.singularPlural.unit.singularSamf'
                  : 'common.singularPlural.unit.singular'
              )}
              error={errors.unitId && getErrorMessage(errors.unitId)}
              name={name}
            >
              <SelectDropdown
                options={entranceUnitOptions()}
                value={value}
                onChange={onChange}
                error={!!errors.unitId}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="role"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('common.form.labels.role')}
              error={errors.role && getErrorMessage(errors.role)}
              name={name}
            >
              <SelectDropdown
                options={unitRoleOptions()}
                value={value}
                onChange={onChange}
                error={!!errors.role}
              />
            </FormControl>
          )}
        />
        <div className="mt-2 flex items-center gap-x-3">
          <Button
            label={translate('common.actions.deny')}
            variant={ButtonVariant.DANGER}
            disabled={loading}
            onClick={onDeny}
          />
          <Button
            label={translate('common.actions.accept')}
            variant={ButtonVariant.PRIMARY}
            type="submit"
            disabled={!isValid || loading}
            onClick={handleSubmit(onApprove, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
