import React, { useState } from 'react'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import {
  PollModel,
  PollOptionModel,
  PollVotesType,
} from '../../../state/models/poll'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import { flatten } from '../../../helpers/array'
import { Poll } from '../Poll'

interface PostPollProps {
  poll: SnapshotOut<typeof PollModel>
  disabled?: boolean
}

export const PostPoll = observer(
  ({ poll, disabled }: PostPollProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, pollStore, unitStore, postStore } = useStores()
    const [selectedValue, setSelectedValue] = useState<string | null>(null)
    const [showResults, setShowResults] = useState(false)
    let hasVoted = false

    const { userId } = authenticationStore

    const userUnits = unitStore.userUnits(userId as string, poll.societyId)

    // one vote per member
    if (poll.type === 'per-user') {
      // have user voted
      const hasUserVoted = poll?.votes?.find((vote) => vote.userId === userId)

      hasVoted = typeof hasUserVoted !== 'undefined'
    }

    // one vote per unit
    else if (poll.type === 'per-unit') {
      // get users who voted
      const usersVoted = poll?.votes?.map((vote) => vote.userId)

      const householdUserIds = flatten(
        userUnits.map((_unit) =>
          _unit.residentsList.map((_resident) => _resident.userId)
        )
      )

      hasVoted =
        typeof usersVoted?.find((user) => householdUserIds.includes(user)) !==
        'undefined'
    }

    const unvote = async (): Promise<void> => {
      setSelectedValue(null)
      await pollStore.unvote(poll._id)
      await postStore.getFeed()
      setShowResults(false)
    }

    const vote = async (
      option: SnapshotOut<typeof PollOptionModel>
    ): Promise<void> => {
      await pollStore.vote(poll._id, option._id)
      await postStore.getFeed()
    }

    const getVotesString = (
      votes: SnapshotOut<typeof PollVotesType> | undefined
    ): string => {
      if (votes) {
        return votes.length === 1
          ? `1 ${translate('poll.votesSingular')}`
          : `${votes.length} ${translate('poll.votesPlural')}`
      }

      return `0 ${translate('poll.votesPlural')}`
    }

    const onRegretVote = (): void => {
      !disabled && unvote()
    }

    const onBackToVote = (): void => {
      !disabled && setShowResults(false)
    }

    const onShowResults = (): void => {
      !disabled && setShowResults(true)
    }

    const renderRegretVote = hasVoted
    const renderBackToVote = !hasVoted && showResults
    const renderShowResults = !hasVoted || showResults
    const renderHouseAlreadyVoted = hasVoted

    const voteInProgress = pollStore.voting === 'pending'

    return (
      <div className="flex flex-col gap-4 rounded-md border border-neutral-40 p-6">
        <p style={theme.textVariants.lead}>{poll.title}</p>
        <div className="flex flex-col gap-3">
          {poll.options.map((_option, i) => {
            const option = castToSnapshot(_option) as SnapshotOut<
              typeof PollOptionModel
            >
            return (
              <Poll
                key={option._id}
                option={option}
                poll={poll}
                index={i}
                showResult={hasVoted || showResults}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                vote={vote}
                loading={voteInProgress}
                disabled={disabled}
              />
            )
          })}
        </div>
        <div className="flex justify-between">
          {renderHouseAlreadyVoted && (
            <p style={theme.textVariants.base}>
              {translate(
                poll.type === 'per-user'
                  ? 'poll.userAlreadyVoted'
                  : 'poll.householdAlreadyVoted'
              )}
            </p>
          )}
          {renderBackToVote && (
            <button onClick={onBackToVote} disabled={disabled}>
              {translate('poll.vote')}
            </button>
          )}
          {renderShowResults && (
            <button onClick={onShowResults} disabled={disabled}>
              <p style={theme.textVariants.base}>
                {translate('poll.showResults')}
              </p>
            </button>
          )}
          {renderRegretVote && (
            <button onClick={onRegretVote} disabled={disabled}>
              {translate('poll.regretVote')}
            </button>
          )}
          <p style={theme.textVariants.base}>({getVotesString(poll.votes)})</p>
        </div>
      </div>
    )
  }
)
