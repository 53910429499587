import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'

import { observer } from 'mobx-react-lite'
import { PostEventDelete } from '../PostEventDelete'
import { EventDeleteModel } from '../../../state/models/event-delete'

interface PostEventsDeleteProps {
  event?: SnapshotOut<typeof EventDeleteModel>
}

export const PostEventsDelete = observer(
  ({ event }: PostEventsDeleteProps): JSX.Element | null => {
    if (!event) {
      return null
    }

    return (
      <>
        <div key={event._id} className="flex flex-col">
          <div className="my-0 border-b border-neutral-80 first:hidden" />
          <PostEventDelete key={event._id} event={event} />
        </div>
      </>
    )
  }
)
