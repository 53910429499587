import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance, SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { PostModel } from '../../../state/models/post'
import { UserModel } from '../../../state/models/user'
import { Icon, IconChoices } from '../../common/Icon'
import { Button, ButtonVariant } from '../../common/Button'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Comment } from '../Comment'
import { CommentInput } from '../CommentInput'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { SocietyModel } from '../../../state/models/society'
import { PostImages } from '../PostImages'
import { PostDocuments } from '../PostDocuments'
import { PostEvents } from '../PostEvents'
import { PostPolls } from '../PostPolls'
import { useModal } from '../../../hooks/useModal'
import { LikesModal } from '../LikesModal'
import { theme } from '../../../theme/theme'
import './post.css'
import { PostLocalOffers } from './PostLocalOffers'
import { LocalOfferModel } from '../../../state/models/local-offer'
import { PostResources } from './PostResources'
import { ResourceModel } from '../../../state/models/resource'
import { PostContacts } from './PostContacts'
import { SocietyContactModel } from '../../../state/models/society-contact'
import { PostFacilities } from './PostFacilities'
import { FacilityModel } from '../../../state/models/facility'
import { PostHeader } from './PostHeader'
import { PostText } from './PostText'
import { useSocietyLockedForCurrentUser } from '../../../hooks/useSocietyLockedForCurrentUser'
import { SeenByModal } from '../SeenByModal'
import { useUserBlockedInSociety } from '../../../hooks/useUserBlockedInSociety'
import { PostBoardroomEvents } from '../PostBoardroomEvents'
import { PostBoardroomEventsDelete } from '../PostBoardroomEventsDelete'
import { PostEventsDelete } from '../PostEventsDelete'

interface PostProps {
  postId: string
  displayComments?: boolean
  scrollToId?: string
}

export const COMMENTS_PER_LOAD = 3

export const Post = observer(
  ({
    postId,
    displayComments = false,
    scrollToId,
  }: PostProps): JSX.Element | null => {
    const [commentSelected, setCommentSelected] =
      useState<boolean>(displayComments)
    const { translate } = useAppTranslation()
    const {
      authenticationStore,
      postStore,
      userStore,
      societyStore,
      localOffersStore,
      resourcesStore,
      societyContactsStore,
      societyBoardroomContactsStore,
      facilitiesStore,
    } = useStores()
    const [loadMoreCounter, setLoadMoreCounter] = useState<number>(1)
    const [endReached, setEndReached] = useState<boolean>(false)
    const [isCommentInView, setIsCommentInView] = useState<boolean>(false)
    const scrollToRef = useRef<HTMLDivElement>(null)
    const {
      show: showLikesModal,
      open: openLikesModal,
      close: closeLikesModal,
    } = useModal()
    const {
      show: showSeenByModal,
      open: openSeenByModal,
      close: closeSeenByModal,
    } = useModal()

    const post = postStore.posts.get(postId) as
      | Instance<typeof PostModel>
      | undefined

    useEffect(() => {
      if (isCommentInView) {
        if (scrollToRef.current) {
          scrollToRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          })
        }
      }
    }, [isCommentInView])

    const hasCommentInView = useCallback((): boolean => {
      return !!(
        post &&
        post.commentIds &&
        post.commentIds.find((comment, index) => {
          return (
            index < loadMoreCounter * COMMENTS_PER_LOAD &&
            comment === scrollToId
          )
        })
      )
    }, [loadMoreCounter, post, scrollToId])

    const onLoadMoreClick = (): void => {
      setLoadMoreCounter((prev) => prev + 1)
    }

    useEffect(() => {
      if (scrollToId) {
        if (hasCommentInView()) {
          setIsCommentInView(true)
        } else {
          onLoadMoreClick()
        }
      }
    }, [isCommentInView, scrollToId, hasCommentInView])

    if (!post) {
      return null
    }

    const author = userStore.users.get(post.author) as
      | Instance<typeof UserModel>
      | undefined

    if (!author) {
      return null
    }

    const currentUserLikesPost = (): boolean => {
      const likes =
        authenticationStore.userId &&
        post.likes.includes(authenticationStore.userId)
      return likes || false
    }

    const user = userStore.currentUser()
    const society = societyStore.societies.get(post.society) as
      | SnapshotOut<typeof SocietyModel>
      | undefined

    const societyLockedForUser = useSocietyLockedForCurrentUser(society)
    const userBlockedInSociety = useUserBlockedInSociety(society?._id)
    const interactionsDisabledForUser = societyLockedForUser

    const isAdmin = user && isUserAdminInSociety(society, user._id)
    const isBoardMember = user && isUserBoardMemberInSociety(society, user._id)
    const isUserAdminOrBoardMember = isAdmin || isBoardMember

    const isResidentPostingDisabled =
      society?.residentPostingDisabledByAdmin && !isUserAdminOrBoardMember

    const onCommentsClick = (): void => setCommentSelected(!commentSelected)

    const toggleLike = (): void => {
      postStore.toggleLike(post._id as string, !currentUserLikesPost())
    }

    const onLikesClick = (): void => {
      post.likes.length > 0 && openLikesModal()
    }

    const commentButtonLabel = (): string => {
      if (!post.commentIds || post.commentIds?.length === 0) {
        return translate('common.makeComment')
      }
      return `${post.commentIds.length} ${translate(
        post.commentIds.length === 1 ? 'common.comment' : 'common.comments'
      )}`
    }

    const showLoadMore =
      commentSelected &&
      post.commentIds &&
      post.commentIds.length > 0 &&
      !endReached

    const isAutomaticallyCreatedPost =
      post.resourcesIds.length > 0 ||
      post.facilitiesIds.length > 0 ||
      post.societyContactsIds.length > 0 ||
      post.boardroomContactsIds.length > 0 ||
      post.localOfferIds.length > 0

    const postHeaderData = {
      postId: post._id,
      isAlert: !!post.isAlert,
      createdAt: post.createdAt,
      role: post.role,
      message: post.message,
      groupsIds: post.groupsIds,
      accessAdminMember: !!post.accessAdminMember,
      accessBoardMember: !!post.accessBoardMember,
      societyId: post.society,
    }

    const nonUserSeenBy =
      post.seenBy?.filter(
        (seenBy) => seenBy.userId !== authenticationStore.userId
      ) || []

    const showSeenBy =
      nonUserSeenBy.length > 0 &&
      // Only display seenby for posts after feature release
      new Date(post.createdAt) > new Date('2024-03-04T00:00:00.000Z')

    return (
      <div className="my-4 flex flex-col gap-4 rounded-md border border-neutral-80 bg-white p-4 md:p-6">
        <PostHeader
          data={postHeaderData}
          author={author}
          societyLockedForUser={societyLockedForUser}
        />
        <div className="post-body mt-0 flex max-w-full flex-col gap-4">
          <PostText
            isAutomaticallyCreatedPost={isAutomaticallyCreatedPost}
            message={post.message}
          />
          <PostImages mediaIds={post.media} />
          <PostDocuments documentsIds={post.documentsIds} />
          <PostEvents eventsIds={post.eventsIds} />
          <PostEventsDelete event={post.eventDeleteData} />

          <PostBoardroomEvents boardroomEventsIds={post.boardroomEventsIds} />
          <PostBoardroomEventsDelete event={post.boardroomEventDeleteData} />
          <PostPolls pollIds={post.pollIds} />
          <PostLocalOffers
            localOffers={
              post.localOfferIds
                .map((_id) => localOffersStore.localOffers.get(_id))
                .filter((_offer) => _offer) as SnapshotOut<
                typeof LocalOfferModel
              >[]
            }
          />
          <PostResources
            resources={
              post.resourcesIds
                .map((_id) => resourcesStore.resources.get(_id))
                .filter((_room) => _room !== undefined) as SnapshotOut<
                typeof ResourceModel
              >[]
            }
          />
          <PostContacts
            societyContacts={
              post.societyContactsIds
                .map((_id) => societyContactsStore.contacts.get(_id))
                .filter((_contact) => _contact !== undefined) as SnapshotOut<
                typeof SocietyContactModel
              >[]
            }
            boardroomContacts={
              post.boardroomContactsIds
                .map((_id) => societyBoardroomContactsStore.contacts.get(_id))
                .filter((_contact) => _contact !== undefined) as SnapshotOut<
                typeof SocietyContactModel
              >[]
            }
          />
          <PostFacilities
            facilities={
              post.facilitiesIds
                .map((_id) => facilitiesStore.facilities.get(_id))
                .filter((_facility) => _facility) as SnapshotOut<
                typeof FacilityModel
              >[]
            }
          />
        </div>
        <div className="border-b border-neutral-80" />
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex">
            <div className="mr-3 flex w-32 rounded border border-neutral-80">
              <button
                className={`flex flex-1 items-center justify-center gap-1.5
                border-neutral-80 p-3
                hover:bg-neutral-96
                ${
                  post.likes.length > 0
                    ? 'rounded-tl rounded-bl border-r'
                    : 'rounded'
                }
                ${
                  interactionsDisabledForUser && 'cursor-not-allowed opacity-50'
                }
                `}
                disabled={societyLockedForUser}
                onClick={toggleLike}
              >
                <Icon
                  icon={IconChoices.LIKE}
                  highlighted={currentUserLikesPost()}
                  size={18}
                />
                <p style={theme.textVariants.base}>
                  {translate('common.like')}
                </p>
              </button>
              {post.likes.length > 0 && (
                <button
                  onClick={onLikesClick}
                  className={`flex flex-1 items-center justify-center
              rounded-tr rounded-br p-3 hover:bg-neutral-96
              ${
                interactionsDisabledForUser && 'cursor-not-allowed opacity-50'
              }`}
                  disabled={societyLockedForUser}
                >
                  {post.likes.length}
                </button>
              )}
            </div>
            <Button
              className={`${commentSelected ? 'bg-neutral-96' : ''}`}
              label={commentButtonLabel()}
              icon={IconChoices.COMMENT}
              onClick={onCommentsClick}
              disabled={
                interactionsDisabledForUser ||
                (isResidentPostingDisabled && post.commentIds?.length === 0)
              }
            />
          </div>
          {showSeenBy && (
            <div>
              <button
                className="mt-2 flex flex-1 items-center gap-1 md:mt-0"
                onClick={openSeenByModal}
              >
                <Icon icon={IconChoices.OPEN_EYE} size={18} />
                <p
                  style={theme.textVariants.caption}
                  className="text-neutral-30"
                >
                  {translate('post.seenBy')} {nonUserSeenBy.length}
                </p>
              </button>
            </div>
          )}
        </div>
        {commentSelected && (
          <div className="mt-2 flex flex-col gap-4">
            {post.commentIds &&
              post.commentIds
                .slice()
                .map(
                  (commentId, i, { length }) =>
                    society && (
                      <Comment
                        key={commentId}
                        commentId={commentId}
                        society={society}
                        loadMoreCounter={loadMoreCounter}
                        index={i}
                        endReached={endReached}
                        setEndReached={setEndReached}
                        length={length}
                      />
                    )
                )}
            <div ref={scrollToRef} />
            {showLoadMore && (
              <Button
                className="flex w-fit"
                onClick={onLoadMoreClick}
                variant={ButtonVariant.TEXT}
                label={translate('post.showMoreComments')}
              />
            )}
            {commentSelected &&
              !userBlockedInSociety &&
              !isResidentPostingDisabled && <CommentInput postId={post._id} />}
          </div>
        )}
        {showLikesModal && (
          <LikesModal
            likes={post.likes}
            societyId={post.society}
            show={showLikesModal}
            close={closeLikesModal}
          />
        )}
        {showSeenByModal && (
          <SeenByModal
            seenBy={nonUserSeenBy}
            societyId={post.society}
            show={showSeenByModal}
            close={closeSeenByModal}
          />
        )}
      </div>
    )
  }
)
